import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import {
  getElder,
  getElderDeliveryGroupByDeliveryGroupId,
  getRecurringOrder,
} from 'graphql/queries';
import {
  updateRecurringOrder,
} from 'graphql/mutations';
import moment from 'moment-timezone';
import {
  getDeliveryStaffIdSchema,
  getRestaurantIdSchema,
} from 'forms/schemas';
import uiSchema from './AdminRecurringOrderForm.uiSchema';
import { toastr } from 'react-redux-toastr';
import cache from 'utilities/cache';

const defaultFormData = {};

const getDatesBetween = (startDate, endDate) => {
  const dates = [];
  const currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate, 'day')) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'day');
  }

  return dates;
};

export default function AdminRecurringOrderForm({ formData: inFormData, editMode='group', mode='add', ...props }) {
  const { t } = useTranslation();
  const [onlyShowFacilityRestaurant, setOnlyShowFacilityRestaurant] = useState(true);
  const [onlyShowFacilityDeliveryStaff, setOnlyShowFacilityDeliveryStaff] = useState(true);
  const dataFormRef = useRef();
  const isInitialRender = useRef(true);

  // load here for translation purpose
  const { default: defaultSchema } = require('./AdminRecurringOrderForm.schema.js');
  const schema = JSON.parse(JSON.stringify(defaultSchema));
  if (mode === 'add') {
    schema.description = '設定週期性排單後，首先會依照設定自動新增最近30天的訂單，每天凌晨會自動新增第31天的訂單，也就是任何時間都會有未來30天的訂單';
  } else {
    schema.description = '請注意，更改週期性排單設定不會影響目前30天內已新增的訂單';
  }
  if (editMode === 'group') {
    delete schema.properties.elderId;
    schema.required.push('deliveryGroupId');
  } else {
    delete schema.properties.deliveryGroupId;
    schema.required.push('elderId');
  }
  const formData = (inFormData || defaultFormData);
  if (!formData.deliveryStaffId) delete formData.deliveryStaffId;

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      if (dataFormRef.current && dataFormRef.current.updateSchema) {
        dataFormRef.current.updateSchema();
      }
    }
  }, [onlyShowFacilityRestaurant, onlyShowFacilityDeliveryStaff]);


  const createFunc = async (data) => {
    const {
      startOn, endOn, elderId, restaurantId, deliveryStaffId, deliveryGroupId, mealItems, repeatOn, deliveryBy,
    } = data;
    const hour = parseInt(deliveryBy.split(':')[0]);
    data.mealSlot = hour <= 14 ? 'lunch' : 'dinner';

    // 建立30天訂單
    const today = moment().tz('Asia/Taipei').format('YYYY-MM-DD');
    const oneMonthAfter = moment().add(30, 'days').tz('Asia/Taipei').format('YYYY-MM-DD');
    if (endOn && endOn < today) {
      return;
    }
    const start = startOn < today ? today : startOn;
    const end = (endOn && (endOn < oneMonthAfter)) ? endOn : oneMonthAfter;
    const toCreateOrders = [];
    const elders = [];
    if (start <= end) {
      const datesBetween = getDatesBetween(start, end);
      if (elderId) {
        const { data: { getElder: data } } = await request(getElder, { id: elderId });
        if (data) {
          elders.push(data);
        }
      }
      if (deliveryGroupId) {
        const { data: { getElderDeliveryGroupByDeliveryGroupId: { items } } } =
          await request(getElderDeliveryGroupByDeliveryGroupId, { deliveryGroupId });
        if (items && items.length !== 0) {
          await items.reduce(async (chain, { elderId, deliveryGroupId, sortOrder }) => {
            await chain;
            const { data: { getElder: data } } = await request(getElder, { id: elderId });
            if (data && data.status === '使用中') {
              elders.push({
                ...data,
                deliveryGroupId,
                sortOrder,
              });
            }
          }, Promise.resolve());
        }
      }
      const hour = parseInt(deliveryBy.split(':')[0]);
      const mealSlot = hour <= 14 ? 'lunch' : 'dinner';
      const total = mealItems.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.price * currentValue.quantity;
      }, 0);
      const totalCost = mealItems.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.cost * currentValue.quantity;
      }, 0);
      elders.forEach((elder) => {
        const deliveryDatetimes = [];
        datesBetween.forEach((date) => {
          const week = moment(date).day();
          if (repeatOn.includes(week)) {
            if ((mealSlot === 'lunch' && elder.lunchRepeatOn && elder.lunchRepeatOn.includes(week)) ||
              (mealSlot === 'dinner' && elder.dinnerRepeatOn && elder.dinnerRepeatOn.includes(week))) {
              const deliveryDatetime = moment.tz(`${date} ${deliveryBy}`, 'Asia/Taipei').toISOString();
              deliveryDatetimes.push(deliveryDatetime);
            }
          }
        });
        if (deliveryDatetimes.length !== 0) {
          toCreateOrders.push({
            elderId: elder.id,
            restaurantId,
            deliveryStaffId,
            mealItems,
            deliveryDatetimes,
            noteForDelivery: elder.noteForDelivery,
            noteForMeal: elder.noteForMeal,
            note: elder.note,
            deliveryGroupId: elder.deliveryGroupId,
            deliveryGroupSortOrder: elder.sortOrder,
            total,
            totalCost,
            deliveryStaffFee: 0,
            category: '送餐',
            paymentMethod: 'PAID',
            source: 'admin',
          });
        }
      });
    }
    return {
      toCreateOrders,
      elders,
      toCreateRecurringOrder: data,
    };
  };

  const updateFunc = async (data) => {
    const hour = parseInt(data.deliveryBy.split(':')[0]);
    data.mealSlot = hour <= 14 ? 'lunch' : 'dinner';

    const newData = JSON.parse(JSON.stringify(data));
    delete newData.restaurant;
    delete newData.elder;
    delete newData.deliveryGroup;
    toastr.warning('請注意，更改週期性排單設定不會影響目前30天內已新增的訂單');
    if (newData.endOn === undefined) {
      newData.endOn = null;
    }
    try {
      await request(updateRecurringOrder, { input: newData });
    } catch (e) {
      console.log(e);
    }
    const { data: { getRecurringOrder: recurringOrder } } = await request(getRecurringOrder, { id: data.id });
    return recurringOrder;
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyShowFacilityRestaurant}
                value={onlyShowFacilityRestaurant}
                onChange={(e) => {
                  setOnlyShowFacilityRestaurant(e.target.checked);
                }}
                name={'onlyShowOrgRestaurant'}
                color="primary"
              />
            }
            label={`只顯示與該機構相關的${t('餐廳')}`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyShowFacilityDeliveryStaff}
                value={onlyShowFacilityDeliveryStaff}
                onChange={(e) => {
                  setOnlyShowFacilityDeliveryStaff(e.target.checked);
                }}
                name={'onlyShowFacilityDeliveryStaff'}
                color="primary"
              />
            }
            label={`只顯示該機構的${t('送餐大使')}`}
          />
        </Grid>
      </Grid>
      <DataForm
        schema={schema}
        uiSchema={uiSchema}
        createFunc={createFunc}
        updateFunc={updateFunc}
        formData={formData}
        extMappings={[{
          key: 'deliveryStaffId',
          func: (deliveryStaffId) => {
            const clientId = cache.get('app:facilityId');
            return getDeliveryStaffIdSchema(deliveryStaffId, null, clientId, true, false, false, true, onlyShowFacilityDeliveryStaff);
          },
        }, {
          key: 'restaurantId',
          func: (restaurantId) => {
            const clientId = cache.get('app:facilityId');
            return getRestaurantIdSchema(restaurantId, null, clientId, false, onlyShowFacilityRestaurant);
          },
        }]}
        ref={dataFormRef}
        {...props}
      />
    </React.Fragment>
  );
}

AdminRecurringOrderForm.propTypes = {
  formData: PropTypes.object,
  editMode: PropTypes.string,
  mode: PropTypes.string,
};
